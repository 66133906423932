
.count {
  border-radius: calc(25px + 2vmin);
  padding: 2px;

  &.has-count {
    background-color: #ddddff;
    color: #111;
  }
}
.points {
  border-radius: calc(25px + 2vmin);
  padding: 2px;
}