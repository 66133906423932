.App[data-theme="light"] {
  --background-primary: #efeff0;
  --background-secondary: #dfdfd9;
  --foreground-subtle: #666;
  --foreground-average: #444;
  --foreground-standout: #222;
  --green: #00AA00
}

.App[data-theme="dark"] {
  --background-primary: #19191f;
  --background-secondary: #2a2a2f;
  --foreground-subtle: #999;
  --foreground-average: #bbb;
  --foreground-standout: #eee;
  --green: #7F7
}

