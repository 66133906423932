@import "../../variables";

.course-of-fire-widget {
  display: flex;
  flex-direction: column;
}

.course-of-fire-selects {
  display: flex;
  flex-direction: row;
}

.course-of-fire-select {
  display: block;
  min-width: 2em;
  padding-block: 6px;
  padding-inline: 3px;
  border: 1px solid #333;
  border-radius: 6px;
  margin: 3px;
}