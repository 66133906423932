.preferences {
  position: relative;

  .chevron {
    display: inline-block;
  }

  .closed {
    display: none;
  }

  .open {
    border-inline-start: 4px solid var(--foreground-standout);
    padding-inline-start: 10px;
  }

  .preferences-container {
    position: absolute;
    right: 0;
    background-color: var(--background-secondary);
    padding: 5px;
    min-width: 20vh;
  }
}