@import "../../variables";

.match-navigation-widget {
  display: flex;
  flex-direction: column;
}

.match-navigation-selects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 6px;
}

.match-navigation-select {
  display: block;
  min-width: 2em;
  padding-block: 6px;
  padding-inline: 3px;
  border: 1px solid #333;
  border-radius: 6px;
  margin: 0;

  &.active {
    border: 3px solid var(--green);
  }

  &.inactive {
  }
}
