@import "variables";

* {
  box-sizing: border-box;
}

header,
footer,
.App-body {
  max-inline-size: 512px;
  margin: 0 auto;
}

header,
footer,
{
  background-color: var(--background-secondary);
}

.App {
  color: var(--foreground-average);
  background-color: var(--background-primary);
  min-block-size: 100vh;
}

.rtl {
  direction: rtl;
}

.App-logo {
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    margin: 0;
  }

  & img {
    margin-left: 15px;
    transform: scaleX(-1);
    height: 6vmin;
    pointer-events: none;
  }
}

.App[data-theme="dark"] .App-logo img {
  filter: drop-shadow(0 0 1px var(--foreground-subtle));
}

.App-header {
  background-color: var(--background-secondary);
  min-height: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + .8vmin);
  color: var(--foreground-subtle);
  padding-bottom: 7px;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: flex;
  flex-direction: row;

  & > section {
    padding: 15px 0;
    width: 100%;
    height: 100%;
  }

  & > .column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50%;
  }
}

.column + .column {
  margin-left: 20px;
}

.subsection {
  & + .subsection {
    margin-top: 10px;
  }
}

.right {
  margin: 0 15px 0 auto;
}

.left {
  margin: 0 auto 0 15px;
}

footer {
  margin-top: 10px;
  padding: 5px 0 15px 15px;

  h4 {
    margin: 5px 0;
  }
}

button + button {
  margin-inline-start: 6px;
}
