.scoreboard-container {
  border: 1px var(--background-secondary) solid;
  overflow: auto;
}

.scoreboard {

  thead {
    tr:nth-of-type(n+1) {
      font-weight: normal;
    }
  }

  td {
    text-align: center;
    min-width: 40px;
  }

  tbody {
    td {
      border: 1px var(--foreground-subtle) solid;

      &:first-of-type {
        border: none;
        font-weight: bold;
        position: sticky;
        left: 0;
        background-color: var(--background-primary);
      }

      &:nth-last-of-type(-n+2) {
        background-color: var(--background-secondary);
      }
    }

    &:last-of-type {
      background-color: var(--background-secondary);
    }
  }
}