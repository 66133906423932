.switcher {

  & + .switcher {
    margin-block-start: 15px;
  }

  &-item {

    & + & {
      margin-inline-start: 15px;
    }
  }

  div {
    display: inline-block;
  }
}
