@import "../../variables";

.score-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 33%;
  padding-inline: 5px;
  padding-block: 10px 20px;
}

button.score-control {
  color: var(--foreground-standout);
}

.score-control {
  width: calc(40px + 2vmin);
  height: calc(40px + 2vmin);
  margin-inline: 0;
  margin-block: 5px;
  background-color: var(--background-secondary);
  border-color: var(--background-primary);
  font-size: 25pt;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(55px + 2vmin);
  border: 2px solid var(--foreground-subtle);
  width: 40px;
  height: 50px;
}

p.score-count {
  margin-inline: 0;
  margin-block: 5px;
}