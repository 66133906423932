.total {
  text-align: end;
  padding-inline: 0 15px;
  padding-block: 5px;
}

.needs-more {
  color: #222;
  background-color: #dd8;
}

.needs-fewer {
  color: #ddd;
  background-color: #c22;
}

.just-right {
  color: #333;
  background-color: #2c2;
}